import React, {useEffect} from "react";
import "./index.scss";
import '../sidebar/SidebarItem.scss';
import './SmallNewsTile.scss';
import './NewsTile.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import NewsTile from "./NewsTile";
import SmallNewsTile from "./SmallNewsTile";
import SidebarItem from "../sidebar/SidebarItem";
import type {News} from "../model/News";
import {Helmet} from "react-helmet";
import MyWordCloud from "../sidebar/MyWordCloud";
import GoogleSearch from "../sidebar/GoogleSearch";
import TopCoins from "../sidebar/TopCoins";
import QAndAForm from "../sidebar/QAndAForm";

type MainProps = {
    pageContext: {
        data: {
            news: News[],
            altNews: News[],
            memeNews: News[],
            stableNews: News[],
            marketNews: News[],
        },
        wordCloud: [string, number][],
    };
}

export default function Home(props: MainProps) {
    const state = props.pageContext.data;

    useEffect(() => {
        if (window && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            const node = document.getElementById("light");
            if (node) node.remove();
        } else {
            const node = document.getElementById("dark");
            if (node) node.remove();
        }
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return <div className={"home"}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>BtcLeaks | Crypto, DeFi and Bitcoin News</title>
                <link rel="canonical" href="https://btcleaks.com"/>
                <meta name="description" content={`All latest Cryptonews. Articles about Bitcoin, Ethereum, Solana, Cardano, Stablecoins, DeFi, Doge, Luna, USDT, USDC, ...`}/>
                <meta name="keywords" content={`Crypto, DeFi, Bitcoin, Ethereum, Doge, Solana, Cardano, Stablecoin, Tether, Decentralized cyrptocurrencies`}/>
                <meta property="og:title" content={`BtcLeaks | Crypto, DeFi and Bitcoin news`} />
                <meta property="og:description" content={`Crypto, DeFi, Bitcoin, Ethereum, Doge, Solana, Cardano, Stablecoin, Tether`}/>
                <meta property="og:image" content="http://btcleaks.com/img_00106.png" />
                <meta property="og:url" content={`https://btcleaks.com`} />
                <meta property="og:type" content="website" />
                <meta property="og:locale" content="en_GB" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="530" />
                <meta property="og:image:alt" content={"BtcLeaks.com"} />
                <meta property="og:image:secure_url" content="https://btcleaks.com/img_00106.png" />
                <meta property="og:image:type" content="image/png" />
                <meta property="og:site.name" content={"btcLeaks.com"} />
                <meta property="article:publisher" content="https://btcLeaks.com"/>
                <meta property="article:author" content="BtcLeaks.com"/>
                <meta name="twitter:site" content="@BtcLeaks" />
                <meta name="twitter:creator" content="Saitama" />
                <meta name="twitter:card" content="summary"/>
                <script type="application/ld+json">{`
                {
                    "mainEntityOfPage": { 
                        "@type": "WebPage",
                        "@id": "btcleaks.com"
                    },
                    "description": "All latest Crypto news. Articles about Bitcoin, Ethereum, Solana, Cardano, Stablecoins, DeFi, Doge, Luna, USDT, USDC, ...",
                    "dateModified": "${new Date().toISOString()}",
                    "datePublished": "${new Date().toISOString()}",
                    "headline": "BtcLeaks | Crypto, DeFi, Stablecoin and Bitcoin news",
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "publisher": {
                        "@type": "Organization",
                        "name": "btcLeaks.com",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://btcleaks.com/image_00106.png"
                        }   
                    }
                }`
                }</script>
            </Helmet>
            <div className={"contentWrapper"}>
                <div className="column1">
                    <div className="content">
                        <div className="gcse-searchresults"/>
                        <section className="mainSection">
                            <h1>Latest Crypto News</h1>
                            {state.news
                                .filter((it, index) => index < 3)
                                .map((it, index) => <NewsTile news={it} key={"big_" + index}/>)}
                            {state.news
                                .filter((it, index) => index > 4 && index < 6)
                                .map((it, index) => <SmallNewsTile news={it} key={"small_" + index}/>)}
                            <article style={{marginBottom:"24px", overflow:"hidden"}}>
                                    <ins className="adsbygoogle" id={"dark"}
                                           style={{display: "block"}}
                                           data-ad-format="fluid"
                                           data-ad-layout-key="-gx-y+aj-9s-iz"
                                           data-ad-client="ca-pub-6013495598121737"
                                           data-ad-slot="1313212288"/>
                                    <ins className="adsbygoogle" id={"light"}
                                           style={{display: "block"}}
                                           data-ad-format="fluid"
                                           data-ad-layout-key="-hf-1a+ae-9a-gl"
                                           data-ad-client="ca-pub-6013495598121737"
                                           data-ad-slot="9324119159"/>
                            </article>
                            {state.news
                                .filter((it, index) => index > 5 && index < 20)
                                .map((it, index) => <SmallNewsTile news={it} key={"small_" + index}/>)}
                        </section>
                    </div>
                </div>
                <div className={"column2"}>
                    <TopCoins/>
                    <div className="sidebar">
                        <div className="sidebarGroup">
                            <ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-6013495598121737"
                                 data-ad-slot="9302285469"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"/>
                        </div>
                    </div>
                    <div className="sidebar">
                        <div className={"sidebarGroup"}>
                            {state.altNews.length > 0 &&
                            <div className={"sidebarHeadline"}>
                                <a href={"/altcoin"} title={"Altcoins | Bitcoin, Ethereum, Solana, Cardano, Polygon, Polkadot"} className={"mainLink"}>
                                    <span>Bitcoin & Altcoin</span> <FontAwesomeIcon icon={faAngleRight} size={"xs"}/>
                                </a>
                            </div>}
                            {state.altNews
                                .map((it, index) => <SidebarItem news={it} key={"side_" + index}/>)}
                            {state.stableNews.length > 0 &&
                            <div className={"sidebarHeadline"}>
                                <a href={"/stablecoin"} title={"Stablecoins | Tether USDT, USDC, DAI"} className={"mainLink"}>
                                    <span>Stablecoins</span> <FontAwesomeIcon icon={faAngleRight} size={"xs"}/>
                                </a>
                            </div>}
                            {state.stableNews
                                .map((it, index) => <SidebarItem news={it} key={"side2_" + index}/>)}
                            {state.memeNews.length > 0 &&
                            <div className={"sidebarHeadline"}>
                                <a href={"/memecoin"} title={"Memecoins | Doge, Shiba, Babydoge, Saitama"} className={"mainLink"}>
                                    <span>Memecoins</span> <FontAwesomeIcon icon={faAngleRight} size={"xs"}/>
                                </a>
                            </div>}
                            {state.memeNews
                                .map((it, index) => <SidebarItem news={it} key={"side3_" + index}/>)}
                            {state.marketNews.length > 0 &&
                                <div className={"sidebarHeadline"}>
                                    <a href={"/markets"} title={"Exchanges | Binance, Coinbase, Bitfinex"} className={"mainLink"}>
                                        <span>Crypto Exchanges</span> <FontAwesomeIcon icon={faAngleRight} size={"xs"}/>
                                    </a>
                                </div>}
                            {state.marketNews
                                .map((it, index) => <SidebarItem news={it} key={"side3_" + index}/>)}
                        </div>
                    </div>
                    <div className="sidebar">
                        <QAndAForm/>
                    </div>
                    <div className="sidebar">
                        <div className="sidebarGroup">
                            <ins className="adsbygoogle"
                                 style={{display:"block"}}
                                 data-ad-client="ca-pub-6013495598121737"
                                 data-ad-slot="9302285469"
                                 data-ad-format="auto"
                                 data-full-width-responsive="true"/>
                        </div>
                    </div>
                    <div className={"sidebar"}>
                        <div className={"sidebarGroup"}>
                            <div className={"sidebarHeadline"}>
                                <span>Search</span>
                            </div>
                            <GoogleSearch/>
                            <MyWordCloud wordCloud={props.pageContext.wordCloud}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
};
